import React, { Component } from 'react'
import styles from './BusinessCase.module.scss'

import 'firebase/firestore'
import { FirebaseAuthConsumer } from '@react-firebase/auth'
import {
    FirestoreCollection,
    FirestoreMutation,
} from '@react-firebase/firestore'
import { navigate } from '@reach/router'

import { uuid } from '../../utils/text'

import BusinessCaseEntry from './BusinessCaseEntry'
import add from '../../assets/icons/add_circle.svg'

class List extends Component {
    render() {
        return <div className={styles.caselist}>{this.props.children}</div>
    }
}

class BusinessCaseList extends Component {
    state = {
        creating: false,
    }

    render() {
        return (
            <>
                <h2>Ihre Business Cases</h2>
                <div className={styles.helptext}>
                    <p>
                        Hier finden Sie eine Übersicht Ihrer bisherigen Business
                        Cases, können Anpassungen vornehmen oder einen neuen
                        Business Case anlegen.
                    </p>
                    <p>
                        Alle Änderungen werden automatisch gespeichert, sodass
                        Sie die Bearbeitung jederzeit unterbrechen und zu einem
                        späteren Zeitpunkt fortsetzen können.
                    </p>
                </div>
                <FirebaseAuthConsumer>
                    {({ isSignedIn, user, providerId }) => {
                        if (this.state.creating) {
                            return (
                                <p>
                                    <strong>
                                        Ihr Business Case wird erstellt, bitte
                                        einen Augenblick Geduld.
                                    </strong>
                                </p>
                            )
                        } else {
                            return (
                                <List>
                                    <FirestoreMutation
                                        type="set"
                                        path={`/users/${
                                            user.uid
                                        }/business-cases/${uuid()}`}>
                                        {({ runMutation }) => {
                                            return (
                                                <section
                                                    className={
                                                        styles.caseentry__new
                                                    }
                                                    onClick={e => {
                                                        this.setState({
                                                            creating: true,
                                                        })
                                                        runMutation({
                                                            input: {
                                                                name: '',
                                                                average_customer_lifetime: 24,
                                                                default_cpl: 0,
                                                                default_ltc: 0,
                                                                phases: [
                                                                    {
                                                                        name:
                                                                            'Setup',
                                                                        id: uuid(),
                                                                        length: 6,
                                                                        cpm: 0,
                                                                        cac: 0,
                                                                    },
                                                                    {
                                                                        name:
                                                                            'Launch',
                                                                        id: uuid(),
                                                                        length: 12,
                                                                        cpm: 0,
                                                                        cac: 0,
                                                                    },
                                                                    {
                                                                        name:
                                                                            'Growth',
                                                                        id: uuid(),
                                                                        length: 24,
                                                                        cpm: 0,
                                                                        cac: 0,
                                                                    },
                                                                    {
                                                                        name:
                                                                            'Scale',
                                                                        id: uuid(),
                                                                        length: 24,
                                                                        cpm: 0,
                                                                        cac: 0,
                                                                    },
                                                                ],
                                                                fundings: [],
                                                                revenues: [
                                                                    {
                                                                        amount: 19.99,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Nutzer-Lizenzen',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'recurring-per-customer',
                                                                    },
                                                                    {
                                                                        amount: 500,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Account Setup',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'once-per-customer',
                                                                    },
                                                                ],
                                                                expenses: [
                                                                    {
                                                                        amount: 5000,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Infrastruktur',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'recurring',
                                                                    },
                                                                    {
                                                                        amount: 350000,
                                                                        id: uuid(),
                                                                        name:
                                                                            'MVP-Entwicklung',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'once',
                                                                    },
                                                                    {
                                                                        amount: 6,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Kundensupport',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'yearly',
                                                                        type:
                                                                            'recurring-per-customer',
                                                                    },
                                                                    {
                                                                        amount: 100000,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Marketing',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'yearly',
                                                                        type:
                                                                            'recurring',
                                                                    },
                                                                    {
                                                                        amount: 10000,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Raumkosten',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'recurring',
                                                                    },
                                                                    {
                                                                        amount: 15000,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Sonstige Betriebskosten',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'recurring',
                                                                    },
                                                                    {
                                                                        amount: 25000,
                                                                        id: uuid(),
                                                                        name:
                                                                            'Teamkosten',
                                                                        override: false,
                                                                        performance: 0,
                                                                        phases: [],
                                                                        recurrence:
                                                                            'monthly',
                                                                        type:
                                                                            'recurring',
                                                                    },
                                                                ],
                                                            },
                                                        }).then(res => {
                                                            const id = res.path
                                                                .split('/')
                                                                .pop()
                                                            navigate(
                                                                `/business-case/${id}/`
                                                            )
                                                        })
                                                    }}>
                                                    <img src={add} alt="Neu" />
                                                    <div>
                                                        Neuen Business Case
                                                        erstellen
                                                    </div>
                                                </section>
                                            )
                                        }}
                                    </FirestoreMutation>

                                    <FirestoreCollection
                                        path={`/users/${user.uid}/business-cases/`}>
                                        {d => {
                                            return d.isLoading
                                                ? 'Daten werden geladen ...'
                                                : d.value?.map((bc, i) => (
                                                      <BusinessCaseEntry
                                                          key={i}
                                                          bc={bc}
                                                          id={d.ids[i]}
                                                          user={user}
                                                      />
                                                  ))
                                        }}
                                    </FirestoreCollection>
                                </List>
                            )
                        }
                    }}
                </FirebaseAuthConsumer>
            </>
        )
    }
}

export default BusinessCaseList
