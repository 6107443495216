import React, { Component } from 'react'
import styles from '../Wizard.module.scss'

import FormField from '../FormField'
import Modal from '../Modal'
import { Button, H3 } from 'candylabs-components'

import { revenueTemplates } from '../../../utils/templates'

import add from '../../../assets/icons/add.svg'

import EntryTable from '../EntryTable'

class Revenues extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedEntryId: null,
            selectedTemplateData: null,
            templates: revenueTemplates,
        }
    }

    handleEdit = revenueId => {
        this.setState(
            {
                selectedEntryId: revenueId,
                selectedTemplateData: null,
            },
            () => this.refs.modal.open()
        )
    }

    handleDelete = revenueId => {
        const updatedRevenues = this.props.data.revenues.filter(
            r => r.id !== revenueId
        )
        this.props.saveData({ input: { revenues: updatedRevenues } })
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <h2>Welche Einnahmen erwarten Sie?</h2>
                <div className={styles.helptext}>
                    <p>
                        Die Berechnung Ihres Business Case Quick Check basiert
                        insbesondere auf Ihren geplanten Umsätzen und Kosten. In
                        diesem Schritt geben Sie bitte Ihre erwarteten Umsätze
                        an.
                    </p>
                    <p>Wir unterscheiden zwischen zwei Umsatzmodellen:</p>
                    <ul>
                        <li>
                            <strong>Einmalige Umsätze</strong> werden pro Kunde
                            nur einmal verbucht. Hierunter fällt beispielsweise
                            der netto Verkaufspreis eines Produktes.
                        </li>
                        <li>
                            <strong>Wiederkehrende Umsätze</strong> werden pro
                            Kunde und pro Zeiteinheit generiert. Hierunter
                            fallen beispielsweise monatliche netto Gebühren für
                            eine Softwarelizenz.
                        </li>
                    </ul>
                    <p>
                        Sie können beliebig viele Umsatzarten anlegen. Damit Sie
                        die Zahlen im fertigen Business Case zuverlässig
                        zuordnen können, legen Sie pro Umsatzart bitte eine
                        aussagekräftige Bezeichnung fest.
                    </p>
                </div>
                <Modal
                    type="revenues"
                    ref="modal"
                    data={this.props.data}
                    entryId={this.state.selectedEntryId}
                    templateData={this.state.selectedTemplateData}
                    saveData={this.props.saveData}></Modal>
                <H3 style={{ marginBottom: '1rem', marginTop: '3rem' }}>
                    Ihre Umsätze
                </H3>
                {this.props.data.revenues.length === 0 && (
                    <p>Sie haben noch keine Umsätze angelegt.</p>
                )}
                {this.props.data.revenues.length > 0 && (
                    <EntryTable
                        type="revenues"
                        data={this.props.data}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                    />
                )}
                <FormField
                    label="Umsatz anlegen"
                    grid="true"
                    style={{ marginTop: '3rem' }}>
                    {/*this.state.templates.map((template, i) => {
                        return (
                            <Button
                                secondary="true"
                                key={i}
                                onClick={e => {
                                    this.setState(
                                        {
                                            selectedEntryId: null,
                                            selectedTemplateData: this.state.templates.filter(
                                                t => t.id === template.id
                                            )[0],
                                        },
                                        () => {
                                            this.refs.modal.open()
                                        }
                                    )
                                }}>
                                <span>{template.name}</span>
                            </Button>
                        )
                    })*/}
                    <Button
                        secondary="true"
                        onClick={e => {
                            this.setState(
                                {
                                    selectedEntryId: null,
                                    selectedTemplateData: null,
                                },
                                () => this.refs.modal.open()
                            )
                        }}>
                        <span>
                            <img src={add} alt="Neuer Umsatz" />
                        </span>
                    </Button>
                </FormField>
            </form>
        )
    }
}

export default Revenues
