import React, { Component } from 'react'
import styles from './Wizard.module.scss'

import cx from 'classnames'

class FormField extends Component {
    render() {
        return (
            <div
                onClick={this.props.onClick}
                className={cx(
                    styles.formfield,
                    this.props.list ? styles.formfield__list : ''
                )}
                style={this.props.style}>
                {this.props.label && <label>{this.props.label}</label>}
                {this.props.helpText && (
                    <span
                        style={{
                            display: 'block',
                            marginTop: '0.2rem',
                            maxWidth: '18rem',
                        }}>
                        <small>{this.props.helpText}</small>
                    </span>
                )}
                {this.props.error && (
                    <span className={styles.formfield__error}>
                        {this.props.error}
                    </span>
                )}
                {this.props.grid && (
                    <div className={styles.grid}>{this.props.children}</div>
                )}
                {!this.props.grid && this.props.children}
            </div>
        )
    }
}

export default FormField
