import React from 'react'
import Plot from 'react-plotly.js'

import styles from './Chart.module.scss'

const layout = {
    autosize: true,
    xaxis: { title: 'Zeit in Monaten', titlefont: { family: 'Montserrat' } },
    yaxis: { title: '€', titlefont: { family: 'Montserrat' } },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    margin: {
        l: 40,
        r: 10,
        b: 40,
        t: 10,
        pad: 0,
    },
}

export default props => {
    let months = []
    for (let i = 1; i <= props.data.output.months; i++) {
        months.push(i)
    }

    let gainPerUser = props.data.output.users.map((u, i) => {
        if (u < 1) {
            return null
        }
        return props.data.output.aggregations.gain[i] / u
    })

    return (
        <div className={styles.chartWrapper}>
            <h3>Ertrag pro Nutzer</h3>
            <Plot
                data={[
                    {
                        x: months,
                        y: gainPerUser,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'Nutzer',
                        line: { color: '#000' },
                    },
                ]}
                layout={layout}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    )
}
