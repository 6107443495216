import React, { Component } from 'react'
import styles from '../Wizard.module.scss'

import FormField from '../FormField'

class Start extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
        }
    }

    handleChange = event => {
        let { name, value } = event.target
        let data = { input: {} }

        if (!value) {
            return this.setState({ errors: { [name]: 'Bitte ausfüllen' } })
        } else {
            let errors = this.state.errors
            delete errors[name]
            this.setState({ errors })
        }
        data.input[name] = value
        this.props.saveData(data)
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <h2>Willkommen,</h2>
                <div className={styles.helptext}>
                    <p>
                        dieser Wizard unterstützt Sie bei der Generierung Ihres
                        Business Case Quick Checks. Die frühzeitige Erstellung
                        eines groben Zahlenwerks ermöglicht die ökonomische
                        Validierung des Marktpotenzials Ihrer Initiative. Dieses
                        Tool unterstützt Sie bei der Erfassung und
                        Strukturierung Ihrer bekannten und antizipierten
                        finanziellen Rahmenbedingungen.
                    </p>
                    <p>
                        Erfassen Sie in den weiteren Abschnitten die Ihnen
                        jeweils bekannten Daten. Am Ende können Sie die Zahlen
                        nochmal anpassen und zur weiteren Verarbeitung
                        exportieren.
                    </p>
                    <p>
                        Bitte beginnen Sie mit einem ausdrucksstarken Namen für
                        Ihre Initiative:
                    </p>
                </div>

                <FormField label="Name" error={this.state.errors.name}>
                    <input
                        type="text"
                        defaultValue={this.props.data.name || ''}
                        name="name"
                        onBlur={this.handleChange}
                    />
                </FormField>
            </form>
        )
    }
}

export default Start
