import React, { Component } from 'react'
import styles from './Wizard.module.scss'

import cx from 'classnames'

class Step extends Component {
    render() {
        return (
            <section
                data-index={this.props.index}
                onClick={this.props.handleClick}
                className={cx(this.props.active ? styles.activestep : '')}>
                <span>{this.props.name}</span>
            </section>
        )
    }
}

class StepIndicator extends Component {
    render() {
        return (
            <div className={styles.stepindicatorwrapper}>
                <Step
                    index={1}
                    name="Start"
                    active={this.props.currentStep === 1}
                    done={this.props.currentStep > 1}
                    handleClick={this.props.handleClick}
                />
                <Step
                    index={2}
                    name="Phasen"
                    active={this.props.currentStep === 2}
                    done={this.props.currentStep > 2}
                    handleClick={this.props.handleClick}
                />
                <Step
                    index={3}
                    name="Kunden"
                    active={this.props.currentStep === 3}
                    done={this.props.currentStep > 3}
                    handleClick={this.props.handleClick}
                />
                <Step
                    index={4}
                    name="Akquisition"
                    active={this.props.currentStep === 4}
                    done={this.props.currentStep > 4}
                    handleClick={this.props.handleClick}
                />
                {/*<Step
                    index={3}
                    name="Finanzierung"
                    active={this.props.currentStep === 3}
                    done={this.props.currentStep > 3}
                    handleClick={this.props.handleClick}
                />*/}
                <Step
                    index={5}
                    name="Umsätze"
                    active={this.props.currentStep === 5}
                    done={this.props.currentStep > 5}
                    handleClick={this.props.handleClick}
                />
                <Step
                    index={6}
                    name="Kosten"
                    active={this.props.currentStep === 6}
                    done={this.props.currentStep > 6}
                    handleClick={this.props.handleClick}
                />
                <Step
                    index={7}
                    name="Auswertung"
                    active={this.props.currentStep === 7}
                    done={this.props.currentStep > 7}
                    handleClick={this.props.handleClick}
                />
            </div>
        )
    }
}

export default StepIndicator
