import React, { Component } from 'react'
import styles from '../Wizard.module.scss'

import FormField from '../FormField'

import { uuid } from '../../../utils/text'

import trash from '../../../assets/icons/delete.svg'
import add from '../../../assets/icons/add_circle.svg'

class Phase extends Component {
    render() {
        return (
            <FormField>
                <input
                    type="text"
                    name="name"
                    defaultValue={this.props.name}
                    onBlur={e => this.props.onChange(e, this.props.id)}
                />
                <input
                    type="number"
                    name="length"
                    min="1"
                    style={{ width: '5rem', textAlign: 'center' }}
                    defaultValue={this.props.length}
                    onBlur={e => this.props.onChange(e, this.props.id)}
                />
                {this.props.length > 1 ? 'Monate' : 'Monat'}
                <img
                    src={trash}
                    alt="Löschen"
                    onClick={e => this.props.onDelete(e, this.props.id)}
                />
            </FormField>
        )
    }
}

class Phases extends Component {
    state = { phases: [] }

    constructor(props) {
        super(props)

        this.state = { phases: props.data.phases }
    }

    addPhase = event => {
        event.preventDefault()
        const phases = [
            ...this.state.phases,
            ...[
                {
                    name: '',
                    id: uuid(),
                    length: 0,
                    cpm: 0,
                    cac: 0,
                },
            ],
        ]
        this.setState({ phases })
    }

    handleDelete = (event, phaseId) => {
        event.preventDefault()
        const phases = this.state.phases.filter(i => i.id !== phaseId)
        this.setState({ phases }, () => {
            this.props.saveData({ input: { phases: this.state.phases } })
        })
    }

    handleChange = (event, phaseId) => {
        let { name, value } = event.target

        if (name === 'length') {
            value = parseInt(value, 10)
        }

        const phases = Object.keys(this.state.phases).map(key => {
            let phase = this.state.phases[key]
            if (phase.id === phaseId) {
                phase[name] = value
            }
            return phase
        })
        this.setState({ phases }, () => {
            this.props.saveData({ input: { phases: this.state.phases } })
        })
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <h2>Venture Lifetime</h2>
                <div className={styles.helptext}>
                    <p>
                        Die Etablierung Ihres Vorhabens im Zielmarkt gliedert
                        sich üblicherweise in die folgenden vier Phasen:
                    </p>
                    <ol>
                        <li>
                            <strong>Setup</strong> – Die Infrastruktur, Team,
                            Kommunikation und Produkt (MVP) werden
                            feinkonzipiert und inklusive der benötigten Prozesse
                            initial realisiert.
                        </li>
                        <li>
                            <strong>Launch</strong> – Das Produkt- und/oder
                            Service-Angebot wird im Zielmarkt platziert und mit
                            den ersten realen Erkenntnissen iteriert.
                        </li>
                        <li>
                            <strong>Growth</strong> – Das Venture Angebot wird
                            zum idealen Market-fit geführt und als relevanter
                            Marktteilnehmer etabliert. Die Service- und/oder
                            Produktarchitektur werden auf die weitere
                            Skalierungsbelastung ausgelegt.
                        </li>
                        <li>
                            <strong>Scale</strong> – Das Venture wird zu einem
                            signifikanten marktbeeinflussenden Faktor in der
                            Wertschöpfung ausgebaut.
                        </li>
                    </ol>

                    <p>
                        Je nach Positionierung des Vorhabens kann eine Anpassung
                        dieser Phasen für eine wirtschaftliche Betrachtung
                        sinnvoll sein. Falls das hier verwendete Modell sich
                        nicht einfach auf Ihr Vorhaben anwenden lässt, können
                        Sie die Benennung der Phasen und deren Dauer flexibel an
                        Ihren Case anpassen.
                    </p>
                    <p>
                        In welche Phasen mit jeweils welcher Dauer möchten Sie
                        Ihren Business Case Quick Check gliedern?
                    </p>
                </div>

                {this.state.phases.map((phase, i) => (
                    <Phase
                        key={i}
                        id={phase.id}
                        name={phase.name}
                        length={phase.length}
                        onChange={this.handleChange}
                        onDelete={this.handleDelete}
                    />
                ))}

                <FormField
                    onClick={this.addPhase}
                    style={{
                        marginTop: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>
                    <img src={add} alt="Hinzufügen" />
                    <span style={{ marginLeft: '1rem' }}>
                        Neue Phase hinzufügen
                    </span>
                </FormField>
            </form>
        )
    }
}

export default Phases
