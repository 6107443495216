import React, { Component } from 'react'
import styles from '../Wizard.module.scss'

import FormField from '../FormField'

class Start extends Component {
    constructor(props) {
        super(props)

        this.state = {
            average_customer_lifetime: props.data.average_customer_lifetime,
            default_cpl: props.data.default_cpl,
            default_ltc: props.data.default_ltc,
            errors: [],
        }
    }

    handleChange = event => {
        let { name, value } = event.target

        if (!value) {
            return this.setState({ errors: { [name]: 'Bitte ausfüllen' } })
        } else {
            let errors = this.state.errors
            delete errors[name]
            this.setState({ errors })
        }

        if (name === 'average_customer_lifetime') {
            value = parseInt(value, 10)
        }
        if (name === 'default_cpl') {
            value = parseFloat(value, 10)
        }
        if (name === 'default_ltc') {
            value = parseFloat(value / 100, 10)
        }

        this.setState({ [name]: value })
    }

    componentWillUnmount() {
        return this.save()
    }

    save = () => {
        this.props.saveData({
            input: {
                average_customer_lifetime: this.state[
                    'average_customer_lifetime'
                ],
                default_cpl: this.state['default_cpl'],
                default_ltc: this.state['default_ltc'],
            },
        })
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <h2>Kundenverhalten</h2>
                <div className={styles.helptext}>
                    <p>
                        Was wissen Sie bereits über Ihre geplanten Kunden? Bitte
                        füllen Sie die folgenden Informationen soweit verfügbar
                        aus den Ergebnissen Ihrer bisherigen Maßnahmen zur
                        Validierung (z.B. Virtual Prototyping) aus und reichern
                        diese mit Ihren Annahmen zur Zielgruppe an.
                    </p>
                </div>
                <FormField
                    label="Cost per  Lead (CPL)"
                    error={this.state.errors.default_cpl}
                    helpText="Was kostest Sie die Akquisition eines Interessenten für
                    Ihren Service?">
                    <input
                        type="number"
                        style={{ width: '5rem', textAlign: 'center' }}
                        defaultValue={this.props.data['default_cpl'] || 0}
                        min="0"
                        max="100000"
                        step="0.01"
                        placeholder="15"
                        name="default_cpl"
                        onChange={this.handleChange}
                        onBlur={this.save}
                    />
                    €
                </FormField>

                <FormField
                    label="Conversion Rate Lead to Customer"
                    error={this.state.errors.default_ltc}
                    helpText="Welchen Anteil Ihrer Interessenten können Sie in registrierte Nutzer konvertieren?">
                    <input
                        type="number"
                        style={{ width: '5rem', textAlign: 'center' }}
                        value={this.state['default_ltc'] * 100 || 0}
                        min="0"
                        max="100"
                        step="0.01"
                        placeholder="1"
                        name="default_ltc"
                        onChange={this.handleChange}
                        onBlur={this.save}
                    />
                    %
                </FormField>
                <FormField
                    label="Customer Lifetime (CL)"
                    error={this.state.errors.average_customer_lifetime}
                    helpText="Wie lange bleibt ein Kunde Ihrem Service im Mittelwert treu?">
                    <input
                        type="number"
                        style={{ width: '5rem', textAlign: 'center' }}
                        value={this.state['average_customer_lifetime'] || 0}
                        min="0"
                        max="10000"
                        placeholder="24"
                        name="average_customer_lifetime"
                        onChange={this.handleChange}
                        onBlur={this.save}
                    />
                    {this.state['average_customer_lifetime'] > 1
                        ? 'Monate'
                        : 'Monat'}
                </FormField>
            </form>
        )
    }
}

export default Start
