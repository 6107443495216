import React from 'react'
import { Button } from 'candylabs-components'
import styles from './Report.module.scss'

export default props => {
    return (
        <div>
            <div className={styles.headerWrapper}>
                <h3>Reports</h3>
                <div>
                    <Button
                        primary="true"
                        onClick={() => {
                            fetch('https://bcqc-report.candylabs.net/', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    uid: props.uid,
                                    bcid: props.bcid,
                                }),
                            })
                        }}>
                        <span>Excel exportieren</span>
                    </Button>
                </div>
            </div>
            <ul className={styles.exports}>
                {props.data.xlsx_files &&
                    props.data.xlsx_files
                        .sort((a, b) => {
                            if (a > b) {
                                return 1
                            } else {
                                return -1
                            }
                        })
                        .map(r => (
                            <li>
                                <a href={r}>
                                    ⬇{' '}
                                    {r.split('/business-case-quick-check-')[2]}
                                </a>
                            </li>
                        ))}
            </ul>
        </div>
    )
}
