import React, { Component } from 'react'
import './Header.module.scss'

import * as firebase from 'firebase/app'
import { IfFirebaseAuthed } from '@react-firebase/auth'
import { Button } from 'candylabs-components'
import { navigate } from '@reach/router'

import home from '../../assets/icons/home.svg'
import logo from '../../assets/candylabs_logo.svg'
import logout from '../../assets/icons/logout.svg'

class Header extends Component {
    render() {
        return (
            <header>
                <img alt="Candylabs Logo" src={logo} />
                <h1>
                    <span onClick={e => navigate('/')}>
                        Business Case Quick Check{' '}
                        {/*<span className={styles.beta}>beta</span>*/}
                    </span>
                </h1>
                <IfFirebaseAuthed>
                    <div
                        style={{
                            marginLeft: 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <Button secondary="true" onClick={e => navigate('/')}>
                            <span>
                                <img src={home} alt="Home" />
                                Start
                            </span>
                        </Button>
                        <Button
                            secondary="true"
                            style={{
                                marginLeft: '1rem',
                            }}
                            onClick={() => {
                                firebase
                                    .auth()
                                    .signOut()
                                    .then(() => {
                                        navigate('/')
                                    })
                            }}>
                            <span>
                                <img src={logout} alt="Abmelden" />
                                Abmelden
                            </span>
                        </Button>
                    </div>
                </IfFirebaseAuthed>
            </header>
        )
    }
}

export default Header
