import React from 'react'

import c from '../../utils/currency'

import styles from './Table.module.scss'

export default props => {
    let colorClass = styles.black
    if (props.color && props.value > 0) {
        colorClass = styles.green
    }
    if (props.color && props.value < 0) {
        colorClass = styles.red
    }
    return (
        <td className={[styles.number, colorClass].join(' ')}>
            {c(Math.round(props.value))}
        </td>
    )
}
