import React from 'react'

import ChartFinancial from './ChartFinancial'
import ChartFinancialLifetime from './ChartFinancialLifetime'
import ChartUsers from './ChartUsers'
import ChartUsersRevenue from './ChartUsersRevenue'
import Table from './Table'
import Report from './Report'
import Kpi from './Kpi'

import styles from './Result.module.scss'

export default props => {
    return (
        <>
            <h2>
                Business Case Quick Check Ergebnis "{props.data.input.name}"
            </h2>
            <div className={styles.helptext}>
                <p>
                    Ihre Angaben wurden zu folgendem Business Case Quick Check
                    zusammengefasst. Sie können einzelne Angaben über den Wizard
                    oder direkt in der tabellarischen Übersicht editieren. Bitte
                    beachten Sie, dass der manuelle Eingriff in die Tabelle
                    unsere Berechnungen überschreibt und die Abhängigkeiten
                    Ihrer Angaben dann nicht mehr vollständig zutreffend sein
                    können.
                </p>
            </div>
            {props.data.output && (
                <>
                    <div className={styles.intro}>
                        <Kpi data={props.data} />
                        <Report
                            data={props.data}
                            uid={props.uid}
                            bcid={props.bcid}
                        />
                    </div>
                    <Table data={props.data} saveData={props.saveData} />

                    <div className={styles.chartGrid}>
                        <ChartFinancial data={props.data} />
                        <ChartFinancialLifetime data={props.data} />
                        <ChartUsers data={props.data} />
                        <ChartUsersRevenue data={props.data} />
                    </div>
                </>
            )}
        </>
    )
}
