const config = {
    apiKey: 'AIzaSyCUp3PwQtEYSG-cJIKFd1mRuxENI8rN2gg',
    authDomain: 'business-case-quick-check-hrzn.firebaseapp.com',
    databaseURL: 'https://business-case-quick-check-hrzn.firebaseio.com',
    projectId: 'business-case-quick-check-hrzn',
    storageBucket: 'business-case-quick-check-hrzn.appspot.com',
    messagingSenderId: '760090293572',
    appId: '1:760090293572:web:d62aad4b80325cddb21311',
    measurementId: 'G-3EDXZW9HWR',
}

export default config
