import React, { Component } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import styles from './Login.module.scss'

import * as firebase from 'firebase/app'

import { Form, Button, H2 } from 'candylabs-components'

const LoginForm = styled(Form)`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    input {
        width: 20rem;
    }

    label {
        margin-bottom: 1rem;
    }
`

class Login extends Component {
    state = { email: '', password: '', error: '', loading: false }

    login = e => {
        this.setState({ loading: true })

        e.preventDefault()
        firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(() => {})
            .catch(err => {
                this.setState({ error: err.message })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <div>
                <H2>
                    Bitte melden Sie sich an um auf Ihre Business Cases
                    zuzugreifen
                </H2>
                {this.state.error && (
                    <div className={cx(styles.message, styles.message__error)}>
                        {this.state.error}
                    </div>
                )}
                <LoginForm onSubmit={this.login}>
                    <label>
                        <input
                            onChange={e => {
                                this.setState({ email: e.target.value })
                            }}
                            type="email"
                            placeholder="E-Mail Adresse"
                            required
                        />
                    </label>
                    <label>
                        <input
                            onChange={e => {
                                this.setState({
                                    password: e.target.value,
                                })
                            }}
                            type="password"
                            placeholder="Passwort"
                            required
                        />
                    </label>
                    <Button
                        primary="true"
                        disabled={this.state.loading}
                        style={{ alignSelf: 'flex-start' }}>
                        {!this.state.loading && <span>Anmelden</span>}
                        {this.state.loading && <span>Anmeldung ...</span>}
                    </Button>
                </LoginForm>
            </div>
        )
    }
}

export default Login
