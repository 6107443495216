import React, { Component } from 'react'

import app from 'firebase/app'
import 'firebase/firestore'

import styles from './BusinessCase.module.scss'

import { navigate } from '@reach/router'

import edit from '../../assets/icons/edit.svg'
import trash from '../../assets/icons/delete.svg'

class BusinessCaseEntry extends Component {
    constructor(props) {
        super(props)

        this.db = app.firestore()
        this.doc = this.db.doc(
            `/users/${this.props.user.uid}/business-cases/${this.props.id}`
        )
    }

    deleteCase = () => {
        this.doc.delete()
    }

    render() {
        const { id, bc } = this.props

        return (
            <section className={styles.caseentry}>
                <div onClick={e => navigate(`/business-case/${id}`)}>
                    {bc.input?.name || '- Bitte vergeben Sie einen Namen -'}
                </div>

                <div>
                    <img
                        src={edit}
                        alt="Bearbeiten"
                        onClick={e => navigate(`/business-case/${id}/wizard`)}
                    />
                    <img src={trash} alt="Löschen" onClick={this.deleteCase} />
                </div>
            </section>
        )
    }
}

export default BusinessCaseEntry
