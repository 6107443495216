import React, { Component } from 'react'
import styles from '../Wizard.module.scss'

import FormField from '../FormField'
import Modal from '../Modal'
import { Button, H3 } from 'candylabs-components'

import { expenseTemplates } from '../../../utils/templates'

import add from '../../../assets/icons/add.svg'

import EntryTable from '../EntryTable'

class Expenses extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedEntryId: null,
            selectedTemplateData: null,
            templates: expenseTemplates,
        }
    }

    handleEdit = expenseId => {
        this.setState(
            {
                selectedEntryId: expenseId,
                selectedTemplateData: null,
            },
            () => this.refs.modal.open()
        )
    }

    handleDelete = expenseId => {
        const updatedExpenses = this.props.data.expenses.filter(
            e => e.id !== expenseId
        )
        this.props.saveData({ input: { expenses: updatedExpenses } })
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <h2>Welche Kosten erwarten Sie?</h2>
                <div className={styles.helptext}>
                    <p>
                        Die Berechnung Ihres Business Case Quick Check basiert
                        insbesondere auf Ihren geplanten Umsätzen und Kosten. In
                        diesem Schritt geben Sie bitte Ihre erwarteten Kosten
                        an.
                    </p>
                    <p>Wir unterscheiden zwischen drei Kostenmodellen:</p>
                    <ul>
                        <li>
                            <strong>Einmalige Kosten</strong> fallen jeweils nur
                            einmal an und werden gleichmäßig auf die
                            ausgewählten Phasen verteilt. Hierunter fällt
                            beispielsweise die initiale Entwicklung eines MVP in
                            der Setup Phase.
                        </li>
                        <li>
                            <strong>Wiederkehrende Kosten</strong> fallen
                            jeweils pro Zeiteinheit in den ausgewählten Phasen
                            an. Hierunter fallen beispielsweise monatliche netto
                            Fixkosten für eine benötigte Server-Infrastruktur.
                        </li>
                        <li>
                            <strong>Nutzerabhängige Kosten</strong> fallen
                            jeweils pro Kunde in den ausgewählten Phasen an.
                            Nutzerabhängige Kosten können einmalig oder
                            wiederkehrend sein. Der hierüber entstehende Aufwand
                            skaliert mit der Anzahl aktiver Kunden. Hierunter
                            fallen beispielsweise einmalige Leistungen zur
                            Leistungsbereitstellung, wie Versand- oder
                            Account-Setup Kosten.
                        </li>
                    </ul>
                    <p>
                        Sie können beliebig viele Kostenarten anlegen. Damit Sie
                        die Zahlen im fertigen Business Case zuverlässig
                        zuordnen können, legen Sie pro Kostenart bitte eine
                        aussagekräftige Bezeichnung fest.
                    </p>
                </div>

                <Modal
                    type="expenses"
                    ref="modal"
                    data={this.props.data}
                    entryId={this.state.selectedEntryId}
                    templateData={this.state.selectedTemplateData}
                    saveData={this.props.saveData}></Modal>

                <H3 style={{ marginBottom: '1rem', marginTop: '3rem' }}>
                    Ihre Kosten
                </H3>
                {this.props.data.expenses.length === 0 && (
                    <p>Sie haben noch keine Kosten erfasst.</p>
                )}
                {this.props.data.expenses.length > 0 && (
                    <EntryTable
                        type="expenses"
                        data={this.props.data}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                    />
                )}
                <FormField
                    label="Kosten erfassen"
                    grid="true"
                    style={{ marginTop: '3rem' }}>
                    {/*this.state.templates.map((template, i) => {
                        return (
                            <Button
                                secondary="true"
                                key={i}
                                onClick={e => {
                                    this.setState(
                                        {
                                            selectedEntryId: null,
                                            selectedTemplateData: this.state.templates.filter(
                                                t => t.id === template.id
                                            )[0],
                                        },
                                        () => {
                                            this.refs.modal.open()
                                        }
                                    )
                                }}>
                                <span>{template.name}</span>
                            </Button>
                        )
                    })*/}
                    <Button
                        secondary="true"
                        onClick={e => {
                            this.setState(
                                {
                                    selectedEntryId: null,
                                    selectedTemplateData: null,
                                },
                                () => this.refs.modal.open()
                            )
                        }}>
                        <span>
                            <img src={add} alt="Neue Kosten" />
                        </span>
                    </Button>
                </FormField>
            </form>
        )
    }
}

export default Expenses
