import React from 'react'
import Plot from 'react-plotly.js'

import styles from './Chart.module.scss'

const layout = {
    autosize: true,
    xaxis: { title: 'Zeit in Monaten', titlefont: { family: 'Montserrat' } },
    yaxis: { title: '€', titlefont: { family: 'Montserrat' } },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    margin: {
        l: 40,
        r: 10,
        b: 40,
        t: 10,
        pad: 0,
    },
}

export default props => {
    let months = []
    for (let i = 1; i <= props.data.output.months; i++) {
        months.push(i)
    }

    let expense = props.data.output.aggregations.expense.map(
        (e, i) => e + props.data.output.aggregations.cac[i]
    )

    return (
        <div className={styles.chartWrapper}>
            <h3>Umsätze und Erträge pro Monat</h3>
            <Plot
                data={[
                    {
                        x: months,
                        y: props.data.output.aggregations.revenue,
                        type: 'scatter',
                        fill: 'tozeroy',
                        name: 'Umsatz',
                        marker: { color: '#93bfc9' },
                    },
                    {
                        x: months,
                        y: expense,
                        type: 'scatter',
                        fill: 'tozeroy',
                        name: 'Kosten',
                        marker: { color: '#c99393' },
                    },
                    {
                        x: months,
                        y: props.data.output.aggregations.gain,
                        type: 'scatter',
                        fill: 'tozeroy',
                        name: 'Ertrag',
                        marker: { color: '#93c996' },
                    },
                ]}
                layout={layout}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    )
}
