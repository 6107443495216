import React, { Component } from 'react'
import styles from '../Wizard.module.scss'

import FormField from '../FormField'

class Acquisition extends Component {
    constructor(props) {
        super(props)

        const phases = [...props.data.phases]
        this.state = {
            phases: phases,
            cpl: props.data.default_cpl,
            ltc: props.data.default_ltc,
        }
    }

    componentDidMount() {
        // Recalculate in case values have changed
        const phases = this.state.phases.map((phase, i) => {
            phase.cpm = parseInt(
                (phase.cac / this.state.cpl) * this.state.ltc,
                10
            )
            phase.cac = Math.floor(
                parseFloat((phase.cpm / this.state.ltc) * this.state.cpl, 10)
            )
            if (isNaN(phase.cpm)) {
                phase.cpm = 0
            }
            if (isNaN(phase.cac)) {
                phase.cac = 0
            }
            return phase
        })
        this.setState({ phases })
    }

    handleChange = (event, phaseId) => {
        let { name, value } = event.target

        if (!value) {
            value = 0
        }

        const phases = this.state.phases
        let phase = phases.filter(p => p.id === phaseId)[0]

        if (name === 'cpm') {
            value = parseInt(value, 10)
            phase['cpm'] = value || 0
            phase['cac'] =
                Math.floor(
                    parseFloat((value / this.state.ltc) * this.state.cpl, 10)
                ) || 0
        } else if (name === 'cac') {
            value = parseFloat(value, 10)
            phase['cpm'] =
                parseInt((value / this.state.cpl) * this.state.ltc, 10) || 0
            phase['cac'] = value || 0
        }

        if (isNaN(value)) {
            value = 0
        }

        phase[name] = value
        this.setState({ phases })
    }

    save = () => {
        const phases = this.state.phases
        this.props.saveData({ input: { phases } })
    }

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <h2>Kundenakquisition</h2>
                <div className={styles.helptext}>
                    <p>
                        Um Kunden zu gewinnen müssen Sie mit Vertriebsaufwänden
                        (CPL/Conversion Rate) rechnen. Bitte geben Sie in diesem
                        Schritt an, wieviele Kunden Sie pro Monat in den
                        jeweiligen Venture Phasen überzeugen wollen.
                    </p>
                    <p>
                        Alternativ können Sie auch das Ihnen für die
                        Kundengewinnung zur Verfügung stehende Budget pro Phase
                        angeben. Beide Werte stehen durch die von Ihnen zuvor
                        eingegebenen Werte in einer direkten Abhängigkeit
                        zueinander.
                    </p>
                </div>
                <table className={styles.wizardTable}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                Neukunden
                                <br />
                                pro Monat
                            </th>
                            <th>
                                Akquisitionskosten
                                <br />
                                pro Monat
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.phases.map((phase, i) => {
                            return (
                                <tr key={i}>
                                    <th>{phase.name}</th>
                                    <td>
                                        <FormField style={{ marginBottom: 0 }}>
                                            <input
                                                type="number"
                                                style={{
                                                    width: '6rem',
                                                    textAlign: 'center',
                                                }}
                                                value={phase.cpm}
                                                min="0"
                                                max="1000000"
                                                step="1"
                                                placeholder="0"
                                                name="cpm"
                                                onChange={e =>
                                                    this.handleChange(
                                                        e,
                                                        phase.id
                                                    )
                                                }
                                                onBlur={this.save}
                                            />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ marginBottom: 0 }}>
                                            <input
                                                type="number"
                                                style={{
                                                    width: '6rem',
                                                    textAlign: 'center',
                                                }}
                                                value={phase.cac}
                                                min="0"
                                                max="1000000"
                                                step="1"
                                                placeholder="0"
                                                name="cac"
                                                onChange={e =>
                                                    this.handleChange(
                                                        e,
                                                        phase.id
                                                    )
                                                }
                                                onBlur={this.save}
                                            />{' '}
                                            €
                                        </FormField>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </form>
        )
    }
}

export default Acquisition
