import React, { Component } from 'react'

import 'firebase/auth'
import {
    IfFirebaseAuthed,
    IfFirebaseUnAuthed,
    FirebaseAuthConsumer,
} from '@react-firebase/auth'

import { Router } from '@reach/router'

import styles from './App.module.scss'

import Header from '../Header/Header'
import Main from '../Home/Main'
import Login from '../Home/Login'
import BusinessCase from '../BusinessCase/BusinessCase'
import BusinessCaseList from '../BusinessCase/BusinessCaseList'

const RouteHome = () => <BusinessCaseList />
const RouteOverview = props => <BusinessCase {...props} />
const RouteWizard = props => <BusinessCase page="wizard" {...props} />

class App extends Component {
    render() {
        return (
            <div className={styles.app}>
                <Header />
                <Main>
                    <FirebaseAuthConsumer>
                        {({ isSignedIn, user, providerId }) => {
                            return (
                                <IfFirebaseAuthed>
                                    <Router>
                                        <RouteHome user={user} path="/" />
                                        <RouteOverview
                                            user={user}
                                            path="business-case/:bcId"
                                        />
                                        <RouteWizard
                                            user={user}
                                            path="business-case/:bcId/wizard"
                                        />
                                    </Router>
                                </IfFirebaseAuthed>
                            )
                        }}
                    </FirebaseAuthConsumer>
                    <IfFirebaseUnAuthed>
                        <Login />
                    </IfFirebaseUnAuthed>
                </Main>
            </div>
        )
    }
}

export default App
