import React from 'react'

import styles from './Result.module.scss'
import c from '../../utils/currency'

export default props => {
    let capital = Math.round(
        props.data.output.aggregations.gain_lifetime.reduce((acc, cur) =>
            acc < cur ? acc : cur
        ) * -1
    )

    if (capital === -0) {
        capital = 0
    }

    let breakEven = props.data.output.aggregations.gain.reduce(
        (acc, cur, idx) => {
            if (cur > 0 && acc <= 0) {
                return idx + 1
            }
            return acc
        }
    )

    const potential = Math.round(
        props.data.output.aggregations.gain_lifetime[
            props.data.output.aggregations.gain_lifetime.length - 1
        ]
    )

    let capitalColor = styles.green
    let breakEvenColor = styles.green
    let potentialColor = styles.green

    if (capital >= 0) {
        capitalColor = styles.red
    }
    if (breakEven <= 0) {
        breakEvenColor = styles.red
    }
    if (potential <= 0) {
        potentialColor = styles.red
    }

    return (
        <div className={styles.kpi}>
            <h3>Kennzahlen</h3>
            <ul>
                <li>
                    <span
                        className={[styles.kpiNumber, capitalColor].join(' ')}>
                        {c(capital)}
                    </span>
                    Kapitalbedarf
                </li>
                <li>
                    <span
                        className={[styles.kpiNumber, breakEvenColor].join(
                            ' '
                        )}>
                        {breakEven <= 0 ? '—' : `${breakEven}. Monat`}
                    </span>
                    Break Even
                </li>
                <li>
                    <span
                        className={[styles.kpiNumber, potentialColor].join(
                            ' '
                        )}>
                        {c(potential)}
                    </span>
                    Ertragspotenzial
                </li>
            </ul>
        </div>
    )
}
