import React, { Component } from 'react'

import Modal from '../Wizard/Modal'
import Cell from './Cell'

import styles from './Table.module.scss'

export default class Table extends Component {
    state = {
        months: [],
        phases: [],
        revenues: {},
        expenses: {},
        dirty: false,
        intervalId: undefined,
        selectedRevenueEntryId: undefined,
        selectedExpenseEntrId: undefined,
    }

    constructor(props) {
        super()
        let months = []
        for (let i = 1; i <= props.data.output.months; i++) {
            months.push(i)
        }
        let intervalId = setInterval(this.save, 1000)
        this.state = {
            months,
            intervalId,
            data: props.data,
        }
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }
    save = () => {
        if (this.state.dirty) {
            this.props.saveData(this.state.data)
            this.setState({ dirty: false })
        }
    }
    overwriteRevenue = (val, revenueId, index) => {
        let revenues = { ...this.state.data.output.revenues }
        let newVal = parseInt(val, 10)
        if (isNaN(newVal)) {
            newVal = 0
        }
        revenues[revenueId][index] = newVal

        let data = { ...this.props.data }
        data.input.revenues = data.input.revenues.map(r => {
            if (r.id === revenueId) {
                r.override = true
            }
            return r
        })
        data.output.revenues = revenues

        this.setState({ data, dirty: true })
    }
    overwriteExpense = (val, expenseId, index) => {
        let expenses = { ...this.state.data.output.expenses }
        let newVal = parseInt(val, 10)
        if (isNaN(newVal)) {
            newVal = 0
        }
        expenses[expenseId][index] = newVal

        let data = { ...this.props.data }
        data.input.expenses = data.input.expenses.map(r => {
            if (r.id === expenseId) {
                r.override = true
            }
            return r
        })
        data.output.expenses = expenses

        this.setState({ data, dirty: true })
    }
    render() {
        return (
            <form onSubmit={e => e.preventDefault()} style={{ width: '100%' }}>
                <div className={styles.wrapper}>
                    <h3>Business Case</h3>
                    <Modal
                        type="revenues"
                        ref="modalRevenues"
                        data={this.props.data.input}
                        entryId={this.state.selectedRevenueEntryId}
                        templateData={null}
                        saveData={this.props.saveData}></Modal>
                    <Modal
                        type="expenses"
                        ref="modalExpenses"
                        data={this.props.data.input}
                        entryId={this.state.selectedExpenseEntryId}
                        templateData={null}
                        saveData={this.props.saveData}></Modal>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.left}>Phase</th>
                                {this.state.data.input.phases.map(ph => (
                                    <th key={ph.id} colSpan={ph.length}>
                                        {ph.name}
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                <th className={styles.left}>Monat</th>
                                {this.state.months.map(month => (
                                    <th key={month}>{month}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={styles.headingRow}>
                                <th>Umsätze</th>
                                {this.state.data.output.aggregations.revenue.map(
                                    (r, i) => (
                                        <Cell value={r} key={i} />
                                    )
                                )}
                            </tr>

                            {Object.keys(this.state.data.output.revenues).map(
                                k => (
                                    <tr key={k}>
                                        <th
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        selectedRevenueEntryId: k,
                                                    },
                                                    () => {
                                                        this.refs.modalRevenues.open()
                                                    }
                                                )
                                            }}>
                                            {
                                                this.props.data.input.revenues.filter(
                                                    f => f.id === k
                                                )[0].name
                                            }
                                        </th>
                                        {this.state.data.output.revenues[k].map(
                                            (v, i) => (
                                                <Cell key={i} value={v} />
                                            )
                                        )}
                                    </tr>
                                )
                            )}

                            <tr className={styles.headingRow}>
                                <th>Kosten</th>
                                {this.state.data.output.aggregations.expense.map(
                                    (r, i) => (
                                        <Cell key={i} value={r} />
                                    )
                                )}
                            </tr>
                            {Object.keys(this.state.data.output.expenses).map(
                                k => (
                                    <tr key={k}>
                                        <th
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        selectedExpenseEntryId: k,
                                                    },
                                                    () => {
                                                        this.refs.modalExpenses.open()
                                                    }
                                                )
                                            }}>
                                            {
                                                this.props.data.input.expenses.filter(
                                                    f => f.id === k
                                                )[0].name
                                            }
                                        </th>
                                        {this.state.data.output.expenses[k].map(
                                            (v, i) => (
                                                <Cell key={i} value={v} />
                                            )
                                        )}
                                    </tr>
                                )
                            )}
                            <tr>
                                <th>Nutzerakquisition</th>
                                {this.state.data.output.aggregations.cac.map(
                                    (r, i) => (
                                        <Cell value={r} key={i} />
                                    )
                                )}
                            </tr>

                            <tr className={styles.headingRowTotal}>
                                <th>Ertrag</th>
                                {this.props.data.output.aggregations.gain.map(
                                    (r, i) => (
                                        <Cell color={true} key={i} value={r} />
                                    )
                                )}
                            </tr>
                            <tr className={styles.headingRowTotalLast}>
                                <th>Ertrag kumuliert</th>
                                {this.props.data.output.aggregations.gain_lifetime.map(
                                    (r, i) => (
                                        <Cell color={true} key={i} value={r} />
                                    )
                                )}
                            </tr>
                            <tr>
                                <th>Kunden</th>
                                {this.props.data.output.users.map((user, i) => (
                                    <td key={i} className={styles.number}>
                                        {user}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.fade} />
                </div>
            </form>
        )
    }
}
