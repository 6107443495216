import React, { Component } from 'react'

import app from 'firebase/app'
import 'firebase/firestore'

import Wizard from '../Wizard/Wizard'

/**
 * BusinessCase component to hold all the relevant data (fetched from Firestore)
 * and handlers to update this data. Will delegate rendering to either the Result
 * or Wizard component, depending on the user's current location.
 */
class BusinessCase extends Component {
    constructor(props) {
        super(props)

        this.db = app.firestore()
        this.doc = this.db.doc(
            `/users/${this.props.user.uid}/business-cases/${this.props.bcId}`
        )

        // Default data - overwritten by Firestore data
        this.state = {
            data: null,
        }
    }

    // Start listener to receive up-to-date data from Firestore
    componentDidMount() {
        this.listenerUnsubscribe = this.doc.onSnapshot(snapshot => {
            console.info('received snapshot from firestore', snapshot)
            const data = snapshot.data()
            this.setState({ data })
        })
    }

    // Clean up listener
    componentWillUnmount() {
        this.listenerUnsubscribe()
    }

    // Method to save the data to Firestore
    saveData = data => {
        this.doc.set(data, { merge: true })
        console.info('data sent to firestore')
    }

    render() {
        if (!this.state.data) {
            return <p>Lade Daten ...</p>
        }

        return (
            <Wizard
                data={this.state.data}
                saveData={this.saveData}
                uid={this.props.user.uid}
                bcid={this.props.bcId}
            />
        )
    }
}

export default BusinessCase
