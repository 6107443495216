import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'

import App from './components/App/App'
import * as theme from './constants/_variables.scss'

import * as firebase from 'firebase/app'
import { FirebaseAuthProvider } from '@react-firebase/auth'
import { FirestoreProvider } from '@react-firebase/firestore'

import firebaseConfig from './firebaseConfig'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
            <FirestoreProvider {...firebaseConfig} firebase={firebase}>
                <App />
            </FirestoreProvider>
        </FirebaseAuthProvider>
    </ThemeProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
