const fundingTemplates = [
    {
        id: '3a8f565d-7c62-414c-9f65-ba9916bcb11d',
        name: 'initial funding',
        amount: 950000,
        type: 'once',
        override: false,
        phases: ['default-phase-1'],
    },
    {
        id: '26c6d25d-430b-4aa8-8be7-ba6142a4b40f',
        name: 'loan',
        amount: 100000,
        type: 'once',
        override: false,
        phases: ['default-phase-2'],
    },
]

const revenueTemplates = [
    {
        id: '856ac232-f5f2-4859-94a4-ec10acdee4f4',
        name: 'paying customer',
        amount: 49.99,
        type: 'factor',
        override: false,
        phases: ['default-phase-2', 'default-phase-3', 'default-phase-4'],
    },
]

const expenseTemplates = [
    {
        id: '436e90b6-c828-4eb0-adfa-dbb0a31d1aa8',
        name: 'marketing phase 1',
        amount: 500,
        type: 'marketing',
        override: false,
        phases: ['default-phase-2'],
    },
    {
        id: '441db024-0ff2-42aa-8846-e27cd9bf4e6b',
        name: 'marketing phase 2+3',
        amount: 1000,
        type: 'marketing',
        override: false,
        phases: ['default-phase-2', 'default-phase-3'],
    },
    {
        id: 'a199af0e-0e5d-40db-8ac5-3e2fe9d20063',
        name: 'marketing phase 4',
        amount: 1200,
        type: 'marketing',
        override: false,
        phases: ['default-phase-3'],
    },
    {
        id: '89692599-a305-4693-875e-e5150e1bd134',
        name: 'operations',
        amount: 14000,
        type: 'fix',
        override: false,
        phases: ['default-phase-4'],
    },
    {
        id: '73baf9c2-9ee7-4276-9477-cd13c5336e3b',
        name: 'interest',
        amount: 550,
        type: 'fix',
        override: false,
        phases: ['default-phase-3', 'default-phase-4'],
    },
    {
        id: 'eca97f06-ee3b-4e85-9338-e2880e127e1a',
        name: 'customer flex cost',
        amount: 5,
        type: 'factor',
        override: false,
        phases: ['default-phase-2', 'default-phase-3'],
    },
    {
        id: '4855e910-6839-48a4-afc7-797f7b50eea2',
        name: 'initial development',
        amount: 20000,
        type: 'fix',
        override: false,
        phases: ['default-phase-2'],
    },
    {
        id: 'e0f8391f-0f6d-447e-96ec-0653aa912af2',
        name: 'development',
        amount: 1000,
        type: 'fix',
        override: false,
        phases: ['default-phase-2', 'default-phase-3'],
    },
    {
        id: '3dfec3ff-1348-4699-a9b8-466e9b3ba9c8',
        name: 'staffing',
        amount: 20000,
        type: 'fix',
        override: false,
        phases: ['default-phase-2', 'default-phase-3'],
    },
]

export { fundingTemplates, revenueTemplates, expenseTemplates }
