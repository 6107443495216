import React, { Component } from 'react'
import styles from './Wizard.module.scss'

import number from '../../utils/currency'

import edit from '../../assets/icons/edit.svg'
import trash from '../../assets/icons/delete.svg'

class EntryTable extends Component {
    render() {
        return (
            <table className={styles.entryTable}>
                <thead>
                    <tr>
                        <th colSpan={2}></th>
                        <th
                            colSpan={this.props.type === 'expenses' ? 4 : 3}
                            style={{ textAlign: 'center' }}>
                            Intervall
                        </th>
                        <th colSpan={this.props.data.phases.length}>Phasen</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>Bezeichnung</th>
                        <th>Betrag</th>
                        {this.props.type === 'expenses' && <th>pro Kunde</th>}
                        <th>einmalig</th>
                        <th>monatlich</th>
                        <th>jährlich</th>
                        {this.props.data.phases.map((phase, i) => {
                            return <th key={i}>{phase.name}</th>
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.data[this.props.type]
                        .sort((a, b) => {
                            if (a.name > b.name) {
                                return 1
                            } else {
                                return -1
                            }
                        })
                        .map((entry, i) => {
                            return (
                                <tr key={i}>
                                    <td>{entry.name}</td>
                                    <td>{number(entry.amount)}</td>
                                    {this.props.type === 'expenses' && (
                                        <td>
                                            {entry.type ===
                                                'once-per-customer' ||
                                            entry.type ===
                                                'recurring-per-customer'
                                                ? '✓'
                                                : ''}
                                        </td>
                                    )}
                                    <td>
                                        {entry.type === 'once-per-customer' ||
                                        entry.type === 'once'
                                            ? '✓'
                                            : ''}
                                    </td>
                                    <td>
                                        {entry.type ===
                                            'recurring-per-customer' ||
                                        entry.type === 'recurring'
                                            ? entry.recurrence === 'monthly'
                                                ? '✓'
                                                : ''
                                            : ''}
                                    </td>
                                    <td>
                                        {entry.type ===
                                            'recurring-per-customer' ||
                                        entry.type === 'recurring'
                                            ? entry.recurrence === 'yearly'
                                                ? '✓'
                                                : ''
                                            : ''}
                                    </td>
                                    {this.props.data.phases.map((phase, j) => {
                                        return (
                                            <td key={j}>
                                                {entry.phases.indexOf(
                                                    phase.id
                                                ) !== -1
                                                    ? '✓'
                                                    : ''}
                                            </td>
                                        )
                                    })}
                                    <td>
                                        <span>
                                            <img
                                                onClick={e => {
                                                    this.props.handleEdit(
                                                        entry.id
                                                    )
                                                }}
                                                src={edit}
                                                alt="Bearbeiten"
                                                title="Bearbeiten"
                                            />
                                            <img
                                                onClick={e =>
                                                    this.props.handleDelete(
                                                        entry.id
                                                    )
                                                }
                                                src={trash}
                                                alt="Löschen"
                                                title="Löschen"
                                            />
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        )
    }
}

export default EntryTable
