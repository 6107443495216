import React, { Component } from 'react'
import styles from './Wizard.module.scss'

import { Button } from 'candylabs-components'
import FormField from './FormField'

import { uuid } from '../../utils/text'

import cx from 'classnames'

class Modal extends Component {
    VALUE_TYPES = {
        fundings: ['once'],
        revenues: ['once-per-customer', 'recurring-per-customer'],
        expenses: [
            'once',
            'recurring',
            'once-per-customer',
            'recurring-per-customer',
        ],
    }

    VALUE_LABELS = {
        once: 'Einmalig',
        recurring: 'Wiederkehrend',
        'once-per-customer': 'Einmalig pro Kunde',
        'recurring-per-customer': 'Wiederkehrend pro Kunde',
    }

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            valueTypes: this.VALUE_TYPES[props.type],
            entry: this._createNewEntry(),
        }
    }

    _createNewEntry = () => {
        return {
            id: uuid(),
            name: '',
            amount: 0.0,
            override: false,
            recurrence: 'monthly',
            performance: 0,
            type: this.VALUE_TYPES[this.props.type][0],
            phases: [],
        }
    }

    _escKeyListener = event => {
        if (event.keyCode === 27) {
            this.close()
        }
    }

    componentDidMount() {
        document.addEventListener('keyup', this._escKeyListener, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this._escKeyListener, false)
    }

    open = () => {
        if (this.props.templateData) {
            this.props.templateData.id = uuid()
            this.setState({
                open: true,
                entry: this.props.templateData,
            })
        } else if (this.props.entryId) {
            const allEntries = this.props.data[this.props.type]
            const entry = allEntries.filter(e => e.id === this.props.entryId)[0]

            this.setState({
                open: true,
                entry: {
                    id: entry.id,
                    name: entry.name || '',
                    amount: entry.amount || 0,
                    override: false,
                    recurrence: entry.recurrence || 'monthly',
                    performance: entry.performance || 0,
                    type: entry.type,
                    phases: entry.phases || [],
                },
            })
        } else if (!this.props.entryId && !this.props.templateData) {
            this.setState({ open: true, entry: this._createNewEntry() })
        }
    }

    close = () => {
        this.setState({ open: false })
    }

    handleChange = event => {
        const { name, value } = event.target
        const entry = this.state.entry
        entry[name] = value
        entry.override = false
        this.setState({ entry })
    }

    handlePhaseSelect = event => {
        const phaseId = event.target.id
        const phases = this.state.entry.phases
        let index

        if (event.target.checked) {
            phases.push(phaseId)
        } else {
            index = phases.indexOf(phaseId)
            phases.splice(index, 1)
        }

        const entry = this.state.entry
        entry.phases = phases
        this.setState({ entry })
    }

    handleSubmit = () => {
        let existingEntries = []
        if (this.props.entryId) {
            existingEntries = this.props.data[this.props.type].filter(
                e => e.id !== this.props.entryId
            )
        } else {
            existingEntries = this.props.data[this.props.type]
        }

        this.props.saveData({
            input: {
                [this.props.type]: [...existingEntries, this.state.entry],
            },
        })
        this.close()
    }

    render() {
        return (
            <>
                {this.state.open && (
                    <>
                        <div
                            onClick={this.close}
                            className={styles.modal__background}></div>
                        <div className={cx(styles.modal, styles.modal__active)}>
                            <main>
                                <h3>
                                    {this.props.type === 'revenues'
                                        ? 'Neuer Umsatz'
                                        : 'Neue Kosten'}
                                </h3>
                                <FormField label="Bezeichnung">
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.state.entry.name}
                                        onChange={this.handleChange}
                                    />
                                </FormField>

                                <FormField label="Betrag">
                                    <input
                                        type="number"
                                        name="amount"
                                        min="0.00"
                                        step="0.01"
                                        value={this.state.entry.amount || 0}
                                        onChange={e => {
                                            this.handleChange({
                                                target: {
                                                    name: e.target.name,
                                                    value: parseFloat(
                                                        e.target.value,
                                                        10
                                                    ),
                                                },
                                            })
                                        }}
                                    />{' '}
                                    EUR
                                </FormField>

                                <FormField
                                    label={
                                        this.props.type === 'revenues'
                                            ? 'Möchten Sie einen einmaligen oder wiederkehrenden Umsatz anlegen?'
                                            : 'Möchten Sie einmalige, wiederkehrende oder nutzerabhängige Kosten anlegen?'
                                    }>
                                    {this.VALUE_TYPES[this.props.type].map(
                                        (type, i) => (
                                            <div
                                                key={i}
                                                className={styles.modal__phase}>
                                                <input
                                                    type="radio"
                                                    name="type"
                                                    id={type}
                                                    value={type}
                                                    checked={
                                                        this.state.entry
                                                            .type === type
                                                    }
                                                    onChange={e => {
                                                        this.handleChange({
                                                            target: {
                                                                name:
                                                                    e.target
                                                                        .name,
                                                                value:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        })
                                                    }}
                                                />
                                                <label htmlFor={type}>
                                                    <span>
                                                        {
                                                            this.VALUE_LABELS[
                                                                type
                                                            ]
                                                        }
                                                    </span>
                                                </label>
                                            </div>
                                        )
                                    )}
                                </FormField>

                                {[
                                    'recurring',
                                    'recurring-per-customer',
                                ].includes(this.state.entry.type) && (
                                    <FormField label="In welchen Zeiträumen fällt dieser Betrag an?">
                                        <div className={styles.modal__phase}>
                                            <input
                                                type="radio"
                                                name="recurrence"
                                                id="monthly"
                                                value="monthly"
                                                checked={
                                                    this.state.entry
                                                        .recurrence ===
                                                    'monthly'
                                                }
                                                onChange={e => {
                                                    this.handleChange({
                                                        target: {
                                                            name: e.target.name,
                                                            value:
                                                                e.target.value,
                                                        },
                                                    })
                                                }}
                                            />
                                            <label htmlFor="monthly">
                                                <span>monatlich</span>
                                            </label>
                                        </div>
                                        <div className={styles.modal__phase}>
                                            <input
                                                type="radio"
                                                name="recurrence"
                                                id="yearly"
                                                value="yearly"
                                                checked={
                                                    this.state.entry
                                                        .recurrence === 'yearly'
                                                }
                                                onChange={e => {
                                                    this.handleChange({
                                                        target: {
                                                            name: e.target.name,
                                                            value:
                                                                e.target.value,
                                                        },
                                                    })
                                                }}
                                            />
                                            <label htmlFor="yearly">
                                                <span>jährlich</span>
                                            </label>
                                        </div>
                                    </FormField>
                                )}

                                <FormField
                                    label={
                                        this.props.type === 'revenues'
                                            ? 'In welchen Phasen erwarten Sie diesen Umsatz?'
                                            : 'In welchen Phasen erwarten Sie diese Kosten?'
                                    }>
                                    {this.props.data.phases.map((phase, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={styles.modal__phase}>
                                                <input
                                                    type="checkbox"
                                                    id={phase.id}
                                                    checked={this.state.entry.phases.includes(
                                                        phase.id
                                                    )}
                                                    onChange={
                                                        this.handlePhaseSelect
                                                    }
                                                />
                                                <label htmlFor={phase.id}>
                                                    <span>{phase.name}</span>
                                                </label>
                                            </div>
                                        )
                                    })}
                                </FormField>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                    <Button
                                        secondary="true"
                                        onClick={this.close}>
                                        <span>Abbrechen</span>
                                    </Button>
                                    <Button
                                        primary="true"
                                        onClick={this.handleSubmit}>
                                        <span>
                                            {this.props.entryId && 'Speichern'}
                                            {!this.props.entryId &&
                                                'Hinzufügen'}
                                        </span>
                                    </Button>
                                </div>
                            </main>
                        </div>
                    </>
                )}
            </>
        )
    }
}

export default Modal
