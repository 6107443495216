import React, { Component } from 'react'
import styles from './Wizard.module.scss'

import StepIndicator from './StepIndicator'

import Start from './Steps/Start'
import Customers from './Steps/Customers'
import Phases from './Steps/Phases'
import Acquistion from './Steps/Acquisition'
// import Fundings from './Steps/Fundings'
import Revenues from './Steps/Revenues'
import Expenses from './Steps/Expenses'
import Result from '../Result/Result'

import { Button } from 'candylabs-components'

const NUM_STEPS = 7

class Wizard extends Component {
    constructor(props) {
        super(props)

        this.state = this.props.data

        this.state.currentStep = NUM_STEPS
        if (
            typeof this.state.output === 'undefined' ||
            this.state.input.name === ''
        ) {
            this.state.currentStep = 1
        }

        this._nextStep = this._nextStep.bind(this)
        this._previousStep = this._previousStep.bind(this)
    }

    _nextStep(event) {
        event.preventDefault()
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 6 ? NUM_STEPS : currentStep + 1
        this.setState({ currentStep })
    }

    _previousStep(event) {
        event.preventDefault()
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({ currentStep })
    }

    get previousButton() {
        let currentStep = this.state.currentStep
        if (currentStep !== 1 && currentStep !== NUM_STEPS) {
            return (
                <Button secondary="true" onClick={this._previousStep}>
                    <span>Zurück</span>
                </Button>
            )
        }

        return null
    }

    get nextButton() {
        let currentStep = this.state.currentStep
        if (currentStep < NUM_STEPS - 1) {
            return (
                <Button
                    primary="true"
                    onClick={this._nextStep}
                    style={{ marginLeft: '1rem' }}>
                    <span>Weiter</span>
                </Button>
            )
        } else if (currentStep === NUM_STEPS - 1) {
            return (
                <Button
                    primary="true"
                    onClick={this._nextStep}
                    style={{ marginLeft: '1rem' }}>
                    <span>Zur Auswertung</span>
                </Button>
            )
        }

        return null
    }

    handleStepClick = event => {
        const index = event.target.dataset.index
        this.setState({ currentStep: parseInt(index, 10) })
    }

    render() {
        return (
            <div className={styles.wizardwrapper}>
                <StepIndicator
                    currentStep={this.state.currentStep}
                    handleClick={this.handleStepClick}
                />
                {this.state.currentStep === 1 && (
                    <Start
                        step={1}
                        data={this.props.data.input}
                        saveData={this.props.saveData}
                    />
                )}
                {this.state.currentStep === 2 && (
                    <Phases
                        step={2}
                        data={this.props.data.input}
                        saveData={this.props.saveData}
                    />
                )}
                {this.state.currentStep === 3 && (
                    <Customers
                        step={3}
                        data={this.props.data.input}
                        saveData={this.props.saveData}
                    />
                )}
                {this.state.currentStep === 4 && (
                    <Acquistion
                        step={4}
                        data={this.props.data.input}
                        saveData={this.props.saveData}
                    />
                )}
                {/*this.state.currentStep === 3 && (
                        <Fundings
                            step={3}
                            data={this.props.data.input}
                            saveData={this.props.saveData}
                        />
                    )*/}
                {this.state.currentStep === 5 && (
                    <Revenues
                        step={5}
                        data={this.props.data.input}
                        saveData={this.props.saveData}
                    />
                )}
                {this.state.currentStep === 6 && (
                    <Expenses
                        step={6}
                        data={this.props.data.input}
                        saveData={this.props.saveData}
                    />
                )}
                {this.state.currentStep === 7 && (
                    <Result
                        step={7}
                        data={this.props.data}
                        saveData={this.props.saveData}
                        uid={this.props.uid}
                        bcid={this.props.bcid}
                    />
                )}

                <div style={{ marginTop: '3rem' }}>
                    {this.previousButton}
                    {this.nextButton}
                </div>
            </div>
        )
    }
}

export default Wizard
